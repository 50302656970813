<template>
    <div class="treeview pb-3 mb-3">
        <ul>
            <span v-for="(item, key) in data" :key="key">
                <li class="border-bottom pb-3 mb-3" v-if="item && item.visible">

                    <menu-item :translations="translations" :item="item" :index="key" @check-toggle="checkToggle"/>

                    <menu-list v-if="item && item.menus && item.menus.length > 0" :data="item.menus" :parents="key"/>
                </li>
            </span>

        </ul>
    </div>
</template>

<script>
    import MenuItem from "@/views/auth/administrator/setup/permission/MenuItem";

    export default {
        name: "menu-list",
        props: ['data', 'parents'],
        components: {
            'menu-list': this,
            MenuItem
        },
        methods: {
            checkToggle(data, match){
                let array = data == '' ? this.data : data
                array.forEach(element => {
                    if(element)
                    {
                        if(element.id == match)
                            this.makeTrue(element, !element.checked)

                        if(element.menus.length > 0)
                            this.checkToggle(element.menus, match)
                    }
                })
            },
            makeTrue(data, status){
                data.checked = status
                if(data.menus && data.menus.length > 0)
                    data.menus.forEach(element => {
                        this.makeTrue(element, status)
                    })

                if(data.actions && data.actions.length > 0)
                    data.actions.forEach(element => {
                        this.makeTrue(element, status)
                    })
            }            
        },        
    }
</script>

<style scoped>

</style>