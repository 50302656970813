<template>
    <section>
        <div class="row">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <form @submit.prevent="submitForm()">
                        <div class="border-bottom pb-3 mb-3">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <h2 class="mb-2">
                                        <router-link :to="$route.meta.backURL" class="btn btn-primary me-2">
                                            <font-awesome-icon :icon="['fas', 'angle-double-left']"></font-awesome-icon>
                                        </router-link>
                                        <span> {{ translations?.permission_for }}: {{ data.dataList.role ? data.dataList.role.name : 'N/A' }}</span>
                                    </h2>
                                    <p class="sm-text">{{ data.dataList.role ? data.dataList.role.responsibility : 'N/A' }}</p>
                                </div>
                            </div>
                        </div>

                        <menu-list v-if="data.dataList.permissions" :data="data.dataList.permissions"></menu-list>

                        <div class="d-flex flex-row-reverse">
                            <button type="submit" class="btn btn-green"> {{ translations?.submit }} </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HelperFunction from "@/common/helpers";
    import {onMounted, computed} from "vue";
    import {useStore} from "vuex";
    import {useRoute} from "vue-router";
    import MenuList from "@/views/auth/administrator/setup/permission/MenuList";

    export default {
        name: "Permission",
        components: {
            MenuList
        },
        setup() {
            const { data, getAllData, storeFormData } = HelperFunction();
            const store     = useStore()
            const route     = useRoute()
            const company   = store.getters.getSelectedBoard
            const user      = store.getters.getProfile
            let setParams = {
                check: true,
                role_id: route.params.id,
                company_id: company.id == 0 ? null : company.id,
                user_id: user.id == 0 ? null : user.id
            };

            data.setURL = vueConfig.Setup.UserPermissionEndPoint
            onMounted(async () => {
                getAllData(data.setURL, setParams)
            })

            const translations = computed(() => {
                return store.state.translations;  
            });

            function submitForm() {
                let formData = setParams
                formData.menus = data.dataList.permissions
                storeFormData({url: vueConfig.Setup.UserPermissionEndPoint}, formData, false, setParams,true)
            }

            return {
                data, submitForm, translations
            }
        }
    }
</script>

<style scoped>

</style>